import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { stagger20ms } from '../../../../@config/animations/stagger.animation';
import { scaleFadeIn400ms } from '../../../../@config/animations/scale-fade-in.animation';
import { fadeInUp400ms } from '../../../../@config/animations/fade-in-up.animation';
import { TableColumn } from '../../../../@config/interfaces/table-column.interface';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExcelService } from '../../../services/excel.service';
import {
  defaultAvatar,
  isMobile,
  PageSize,
  PageNumber,
  today,
  primaryColor,
} from '../../variables/variables';
import { secureStorage } from '../../functions/secure-storage';
import { Router } from '@angular/router';
import {
  GetLanguage,
  getCurrentUserRoles,
  downloadFromBlobResponse,
  GetEventMessage,
} from '../../functions/shared-functions';
import { debounceTime } from 'rxjs';
import { LoaderService } from '../../../services/loader.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { GlobalService } from '../../../services/global.service';
import { NavigationService } from '../../../../@config/services/navigation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '../../../services/core.service';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  providers: [],
  animations: [
    stagger20ms,
    fadeInUp400ms,
    scaleFadeIn400ms,
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class DataTableComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ///////////////////////////////////////////////////////////////////////////////////////
  @Input() data: T[];
  @Input() total = 0;
  @Input() next_page;
  @Input() has_nav = true;
  @Input() noPaginatedData = false;
  @Input() requestStatus;
  @Input() columns: TableColumn<T>[];
  @Input() subDisplayedColumns;
  @Output() sitePageContentEvent = new EventEmitter<any>();
  @Input() pageSize = PageSize;
  @Input() pageIndexNum = 0;
  @Input() pageSizeOptions = [10, 20, 50];
  @Input() pluralTableLabel: string;
  @Input() singleTableLabel: string;
  @Input() hasAdd: boolean;
  @Input() otherReviews = true;
  @Input() showAppointments = false;
  @Input() enableRowClick: boolean;
  @Input() hasCollege: boolean;
  @Input() hasPrintReceipt: boolean;
  @Input() hasPrintBarcode: boolean;
  @Input() hasTransformToUser: boolean;
  @Input() hasAddToCompany: boolean;
  @Input() hasTransformToCompany: boolean;
  @Input() hasDataReviewTransfer: boolean;
  @Input() hasUploadAppForm: boolean;
  @Input() hasReplyVerifyCertificate: boolean;
  @Input() hasReview: boolean;
  @Input() hasDelete: boolean;
  @Input() hasView: boolean;
  @Input() hasTransfer: boolean;
  @Input() hasModify: boolean;
  @Input() expandedTable: boolean;
  @Input() hasRePrint: boolean;
  @Input() hasServerSearch: boolean = true;
  @Input() statues: any;
  @Input() clearSelection: any;
  @Input() hasAccServiceRequestDelete: any;
  @Input() hasClassificationsRequestDelete: any;
  @Input() hasAccServiceRequestCancelAcc: any;
  @Input() hasClassificationRequestCancel: any;
  @Input() hasAccServiceRequestAcc: any;
  @Input() hasClassificationRequestApproveForFree: any;
  @Input() hasRequestStatus: any;
  @Input() hasClassificationRequestStatus: any;
  @Input() hasTransformToCompletingDocuments: any;
  @Input() hasAddAllResultsBtn: any;
  @Input() evaluationStatus: any;
  @Input() hasTransferTestToExam: any;
  @Input() hasTransferTestToInterview: any;
  @Input() hasAvailStatus: any;
  @Input() hasTransferAllToWorkforceBtn: any;
  @Input() hasStopTransferAllToWorkforceBtn: any;
  @Input() hideAcceptance = false;
  @Input() hasClassificationsCard: any;
  @Input() hasClassificationsCertificate: any;
  @Input() hasClassification: boolean;
  @Input() hasPrintInquire: boolean;
  @Input() hasUpdatePersonalInfo: boolean;
  @Input() hasUpdateDocuments: boolean;
  @Input() hasUpdateClassification: boolean;
  @Input() pagination: any;
  @Input() monthFilter = [];
  @Input() hasUnlink: boolean;
  @Input() hasclassificationReviewDataDelete: boolean;
  ///////////////////////////////////////////////////////////////////////////////////////
  @Output() getData = new EventEmitter<any>();
  @Output() openRow = new EventEmitter<any['id']>();
  @Output() deleteData = new EventEmitter<any>();
  @Output() logoutData = new EventEmitter<any>();
  @Output() collectSelectedRow = new EventEmitter<any>();
  @Output() createRow = new EventEmitter<any>();
  @Output() viewRow = new EventEmitter<any>();
  @Output() complaint_reply = new EventEmitter<any>();
  @Output() complaint_transfer = new EventEmitter<any>();
  @Output() changed_status = new EventEmitter<any>();
  @Output() fireGetServerData = new EventEmitter<any>();
  @Output() openUserRecord = new EventEmitter<any>();
  @Output() navigateToReview = new EventEmitter<any>();
  @Output() navigateToColleges = new EventEmitter<any>();
  @Output() cachierCollect = new EventEmitter<any>();
  @Output() navigateToDataReviewTransfer = new EventEmitter<any>();
  @Output() navigateToPersonDataAboardTransfer = new EventEmitter<any>();
  @Output() transformPersonalData = new EventEmitter<any>();
  @Output() addToCompany = new EventEmitter<any>();
  @Output() navigateToPrintReviewData = new EventEmitter<any>();
  @Output() navigateToReplyVerifyCertificate = new EventEmitter<any>();
  @Output() navigateToReprint = new EventEmitter<any>();
  @Output() changeReqStatusEvent = new EventEmitter<any>();
  @Output() cancelAccreditation = new EventEmitter<any>();
  @Output() updateForFree = new EventEmitter<any>();
  @Output() addRequestPayment = new EventEmitter<any>();
  @Output() goPanelMembers = new EventEmitter<any>();
  @Output() goAllExaminers = new EventEmitter<any>();
  @Output() addPFMember = new EventEmitter<any>();
  @Output() goPanelExaminers = new EventEmitter<any>();
  @Output() addExaminer = new EventEmitter<any>();
  @Output() addOldToPanel = new EventEmitter<any>();
  @Output() removeExaminer = new EventEmitter<any>();
  @Output() transfer = new EventEmitter<any>();
  @Output() addResult = new EventEmitter<any>();
  @Output() transferDocs = new EventEmitter<any>();
  @Output() accreditResults = new EventEmitter<any>();
  @Output() transferTestReview = new EventEmitter<any>();
  @Output() acceptanceEvent = new EventEmitter<any>();
  @Output() addService = new EventEmitter<any>();
  @Output() requiredDocsEvent = new EventEmitter<any>();
  @Output() transferLink = new EventEmitter<any>();
  @Output() goToCompanyMembers = new EventEmitter<any>();
  @Output() changePersonStatus = new EventEmitter<any>();
  @Output() printCertificate = new EventEmitter<any>();
  @Output() transferAllToWorkforce = new EventEmitter<any>();
  @Output() stopTransferAllToWorkforce = new EventEmitter<any>();
  @Output() goAuditInfo = new EventEmitter<any>();
  @Output() uploadApplicationForm = new EventEmitter<any>();
  @Output() printCard = new EventEmitter<any>();
  @Output() receiveStamp = new EventEmitter<any>();
  @Output() openSubData = new EventEmitter<any>();
  @Output() rowClickedOutput = new EventEmitter<any>();
  @Input() editAddSubtable;
  @Output() addPersonStatus = new EventEmitter<any>();
  @Output() blockUser = new EventEmitter<any>();
  @Output() workforceAction = new EventEmitter<any>();
  @Output() sendToNocAction = new EventEmitter<any>();
  @Output() editPersonStatus = new EventEmitter<any>();
  @Output() editField = new EventEmitter<any>();
  @Output() classificationDetails = new EventEmitter<any>();
  @Output() verifyNoc = new EventEmitter<any>();
  @Output() monthSelected = new EventEmitter<any>();
  @Output() registerSuperAdminRoles = new EventEmitter<any>();
  ///////////////////////////////////////////////////////////////////////////////////////
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  pageIndex = 0;
  inClassifications =
    this.navigationService.getParent(null) === 'classification';
  // visibleColumns: Array<keyof T | string>;
  dataSource = new MatTableDataSource<T>();
  selectedRow;
  clickedRow;
  isMobile = isMobile;
  defaultAvatar = defaultAvatar;
  language = GetLanguage();
  filtering = false;
  invalidGrades = [];
  finalGrade: number;
  statuses = [
    {
      name: 'Opened',
      value: 1,
      color: '#026c17',
    },
    {
      name: 'Pending',
      value: 2,
      color: '#efc48f',
    },
    {
      name: 'Closed',
      value: 3,
      color: '#b90707',
    },
  ];
  url = window.location.href;
  loading = false;
  roles = getCurrentUserRoles();
  expandedElement;
  hasFilter: boolean = false;
  listName: string;
  hasGrid: boolean;
  filterOpened: boolean;
  downloadReportPdf: boolean;
  downloadReportExcel: boolean;
  inList$ = this.navigationService.pageIsList$;
  list_type: string = 'table';
  valuesGoingToFilter;
  primary = primaryColor;

  constructor(
    private excelService: ExcelService,
    private router: Router,
    private globalService: GlobalService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    public coreService: CoreService,
    public cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
  ) {}

  ngOnInit() {
    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this), debounceTime(1000))
      .subscribe((value) => this.onFilterChange(value));
    this.subscribeToLoading();
    this.subscribeToListData();
    this.subscribeToListType();
  }

  subscribeToListType() {
    this.globalService.toggleListGridObservable$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.list_type = res;
      });
  }

  subscribeToLoading() {
    this.loaderService.loaderState
      .pipe(untilDestroyed(this))
      .subscribe((loader) =>
        setTimeout(() => {
          this.loading = !!loader?.show;
        }),
      );
  }

  subscribeToListData() {
    this.globalService.listEventObservable$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.filterOpened = res.filterOpened;
        this.hasFilter = res.hasFilter;
        this.hasGrid = res.hasGrid;
        this.downloadReportPdf = res.downloadReportPdf;
        this.downloadReportExcel = res.downloadReportExcel;
        this.listName = res.listName;
        this.valuesGoingToFilter = res.valuesGoingToFilter;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.handleColumnsSort();
    }

    if (this.clearSelection) {
      this.selection.clear();
    }
    if (changes.data) {
      this.dataSource.filter = null;
      this.dataSource.data = this.data;
      if (this.noPaginatedData) {
        // this if the coming data is all the data without pagination
        // this.dataSource.paginator = this.paginator;
      }
      this.cdr.detectChanges();
    }
  }

  filter(event) {
    this.globalService.filterDataChangedSubject.next(event);
  }

  registerSuperAdmin() {
    this.registerSuperAdminRoles.emit(true);
  }

  toggleFilter() {
    this.filterOpened = !this.filterOpened;
  }

  toggleList(type) {
    this.list_type = type;
  }

  handleColumnsSort() {
    this.columns?.map((column: any) => {
      column.hasSort = column?.label?.length && column.hasSort !== false;
    });
  }

  onFilterChange(value: string) {
    this.filtering = !!value;
    // if (!value) {
    //   // console.log('value', value)
    //   return;
    // }
    value = value.trim();
    value = value.toLowerCase();
    // this.dataSource.filter = value;
    if (this.hasServerSearch) {
      this.fireGetServerData.emit(`&search=${value}`);
    } else {
      this.dataSource.filter = value;
    }
    // if (this.dataSource.filteredData.length === 0) {
    // }
  }

  changeSort(event) {
    if (
      event.active === 'name' ||
      event.active === 'title' ||
      event.active === 'body' ||
      event.active === 'question' ||
      event.active === 'answer' ||
      event.active === 'text' ||
      event.active === 'content' ||
      event.active === 'description'
    ) {
      event.active =
        GetLanguage() === 'ar' ? `${event.active}_ar` : `${event.active}_en`;
    }
    if (
      event.active === 'teacher' ||
      event.active === 'student' ||
      event.active === 'exam' ||
      event.active === 'language' ||
      event.active === 'country'
    ) {
      event.active = `${event.active}_id`;
    }
    if (
      event.active === 'note' ||
      event.active === 'timeslots' ||
      event.active === 'preferred_days' ||
      event.active === 'teacher_requests'
    ) {
      return;
    }
    const sort = `&sort_by=${event.active}&direction=${
      event.direction || 'asc'
    }`;
    this.fireGetServerData.emit(sort);
  }

  get visibleColumns(): Array<keyof T | string> {
    return this.columns
      ?.filter((column) => column.visible !== false)
      .map((column) => column.property);
  }

  get filterColumns(): TableColumn<T>[] {
    return this.columns?.filter((column) => column.label?.length);
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  headerClicked(event) {
    event.preventDefault();
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  delete(selected) {
    this.deleteData.emit(selected);
  }

  logoutSelected(selected) {
    this.logoutData.emit(selected);
  }

  create() {
    this.createRow.emit();
  }

  changePage(event) {
    // if (this.next_page !== -1) {
    this.getData.emit({ ...event, pageIndex: event.pageIndex + 1 });
    this.selection.clear();
    // }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows || numSelected === this.total;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selection.selected.length = 0;
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
      this.selection.selected.length = this.total;
    }
  }

  downloadReportAsPdf(event) {
    this.globalService.downloadAsPdf.next(event);
  }

  downloadReportAsExcel(event) {
    this.globalService.downloadAsExcel.next(event);
  }

  exportExcel(type) {
    const data =
      type === 'select' ? this.selection.selected : this.dataSource.data;
    const headers = this.filterColumns.map((column) => column.label);
    const values = this.filterColumns.map((column) => column.property);
    this.excelService.generateExcel(data, values, headers, null);
  }

  sendNotification() {
    this.selection.selected.forEach((item) => {
      item.type = this.pluralTableLabel;
    });
    secureStorage.setItem('selected', this.selection.selected);
    this.router.navigate([
      '/admin-management/notifications/create-notification',
    ]);
  }

  view(row) {
    this.viewRow.emit(row);
    this.complaint_reply.emit(row);
  }

  transferToSupportTicket(row) {
    this.complaint_transfer.emit(row);
  }

  reply(row) {
    this.complaint_reply.emit(row);
  }

  changeStatus(event) {
    const e = {
      row: this.selectedRow,
      value: event,
    };
    if (this.selectedRow.status.value !== event?.value) {
      this.changed_status.emit(e);
    }
  }

  goToReprint(row) {
    this.navigateToReprint.emit(row);
  }

  rowClicked(row) {
    if (row.subData) {
      this.openSubData.emit(row);
    } else {
      this.expandedElement = this.expandedElement === row ? null : row;
      //this.expandedElement.hasFilter = false;
    }
    this.rowClickedOutput.emit(row);
    this.clickedRow = row;
  }

  openFile(row) {
    this.openUserRecord.emit(row);
  }

  gotoColleges(row) {
    this.navigateToColleges.emit(row);
  }

  printReviewData(row, type) {
    this.navigateToPrintReviewData.emit({ row, type });
  }

  goToReview(row) {
    this.navigateToReview.emit(row);
  }

  goToCachierCollect(row) {
    // setTimeout(() => {
    this.cachierCollect.emit(row);
    // }, 1000);
  }

  dataReviewTransfer(row, type) {
    this.navigateToDataReviewTransfer.emit({ row, type });
  }

  goToReplyVerifyCertificate(row) {
    this.navigateToReplyVerifyCertificate.emit(row);
  }

  reviewUser(row) {
    const personalId = secureStorage.encryptItem(
      row?.personDataId ? row?.personDataId : row?.id,
    );
    const otherReviews = secureStorage.encryptItem(this.otherReviews);
    const showAppointments = secureStorage.encryptItem(this.showAppointments);
    window.open(
      `/admin-management/engineer?pid=${personalId}&otherReviews=${otherReviews}&showAppointments=${showAppointments}`,
      '_blank',
    );
  }

  reviewPersonAboard(row) {
    this.navigateToPersonDataAboardTransfer.emit(row);
  }

  changeReqStatus(row, reqStatusId, index) {
    this.changeReqStatusEvent.emit({ row, reqStatusId, index });
  }

  changePersonStatusEvent(row, reqStatusId) {
    this.changePersonStatus.emit({ row, reqStatusId });
  }

  transferPersonData(row, type) {
    this.transformPersonalData.emit({ row, type });
  }

  transferEvent(row, type) {
    this.transfer.emit({ row, type });
  }

  addUserToCompany(row) {
    this.addToCompany.emit(row);
  }

  updateForFreeEvent(row) {
    this.updateForFree.emit({ row });
  }

  cancelAccreditationEvent(row) {
    this.cancelAccreditation.emit({ row });
  }

  addRequestPaymentEvent(row) {
    this.addRequestPayment.emit({ row });
  }

  goPanelMembersEvent(row) {
    this.goPanelMembers.emit(row);
  }

  goAllExaminersEvent(row) {
    this.goAllExaminers.emit(row);
  }

  addPMemberEvent(row, index) {
    row['index'] = index;
    this.addPFMember.emit(row);
  }

  goPanelExaminersEvent(row) {
    this.goPanelExaminers.emit(row);
  }

  addExaminerEvent(row, index) {
    row['index'] = index;
    this.addExaminer.emit(row);
  }

  addOldPersonToPanel(row, index) {
    row['index'] = index;
    this.addOldToPanel.emit(row);
  }

  addClassificationService(row, index) {
    row['index'] = index;
    this.addService.emit(row);
  }

  removeExaminerEvent(row, index) {
    row['index'] = index;
    this.removeExaminer.emit(row);
  }

  transferRow(row, type) {
    this.transferDocs.emit({ row, type });
  }

  goRequiredDocs(row) {
    this.requiredDocsEvent.emit(row);
  }

  accreditResultsEvent(row) {
    this.accreditResults.emit(row);
  }

  transferTestReviewEvent(row, type) {
    this.transferTestReview.emit({ row, type });
  }

  printCertificateEvent(row) {
    this.printCertificate.emit(row);
  }

  goSitePageContent(row) {
    this.sitePageContentEvent.emit(row);
  }

  change(type, event, index) {
    const value = event.target.value;
    if (type === 'Grade') {
      if (this.validateGrade(value, index)) {
        this.data[index]['grade'] = value;
      }
    } else if (type === 'Comment') {
      this.data[index]['comment'] = value;
    }
  }

  validateGrade(grade, index) {
    this.finalGrade = this.data[index]['panel']?.finalGrade;
    if (grade < 0 || grade > this.finalGrade) {
      this.invalidGrades[index] = true;
      return false;
    } else {
      this.invalidGrades[index] = false;
      return true;
    }
  }

  addResultEvent() {
    if (this.invalidGrades.includes(true)) {
      this.toastrService.error(
        this.translateService.instant('Please enter a valid results'),
      );
    } else {
      this.addResult.emit(this.data);
    }
  }

  acceptance(row, type) {
    this.acceptanceEvent.emit({ row, type });
  }

  goToCompanyMembersEvent(row) {
    this.goToCompanyMembers.emit(row);
  }

  transferAllToWorkforceEvent(selected) {
    this.transferAllToWorkforce.emit(selected);
  }

  get hasAllWorkForceToTransfer() {
    return this.data.some((el: any) => el.send);
  }

  stopTransferAllToWorkforceEvent(selected) {
    this.stopTransferAllToWorkforce.emit(selected);
  }

  goAuditInfoEvent(row) {
    this.goAuditInfo.emit(row);
  }

  scrollTable(e) {
    // // console.log('sss', e.target.scrollLeft)
    // const paginator = document.getElementById('paginator');
    // const header = document.getElementById('table-bar');
    // if (e.target.scrollLeft <= 0) {
    //   header.style.position = 'fixed';
    //   header.style.left = '0';
    // } else {
    //   header.style.position = 'relative';
    //   header.style.right = 'auto';
    // }
  }

  checkRoles(type) {
    const label = this.singleTableLabel;
    if (label === 'Ministry') {
      return this.roles.includes(`${type}Ministry`);
    } else if (label === 'Verification certificates') {
      return this.roles.includes(`${type}CertVerificationType`);
    } else if (label === 'Education level') {
      return this.roles.includes(`${type}CertVerificationType`);
    } else if (label === 'Verification statuses') {
      return this.roles.includes(`${type}CertVerificationStatus`);
    } else if (label === 'Company') {
      return this.roles.includes(`${type}Company`);
    } else if (label === 'Company rating') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Country') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}Country`);
    } else if (label === 'Discount') {
      return this.roles.includes(`${type}DiscountType`);
    } else if (label === 'Document type') {
      return this.roles.includes(`${type}DocumentType`);
    } else if (label === 'Education rating') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}EduRating`);
    } else if (label === 'Evaluation status') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Evaluation type') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Gender') {
      return this.roles.includes(`${type}Gender`);
    } else if (label === 'Job') {
      return this.roles.includes(`${type}Jobs`);
    } else if (label === 'Nationality') {
      if (type === 'Delete') {
        return this.roles.includes(`BoShowSuperAdmin`);
      } else {
        return this.roles.includes(`${type}Nationality`);
      }
    } else if (label === 'Payment method') {
      return this.roles.includes(`${type}PaymentMethod`);
    } else if (label === 'Profession') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}Profession`);
    } else if (label === 'Request status') {
      if (type === 'Create') {
        return this.roles.includes(`BoShowSuperAdmin`);
      } else {
        return this.roles.includes(`${type}RequestStatus`);
      }
    } else if (label === 'Residency type') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Services') {
      if (type === 'Create') {
        return this.roles.includes(`BoShowSuperAdmin`);
      } else {
        return this.roles.includes(`${type}Service`);
      }
    } else if (label === 'Audit category') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Audit data') {
      return false;
    } else if (label === 'Status type') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Sub profession') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}SubProfession`);
    } else if (label === 'Application pages settings') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Faculty member') {
      return this.roles.includes(`${type}FacultyMember`);
    } else if (label === 'Holiday') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}Holiday`);
    } else if (label === 'Booking Setting') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Shifts') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}Shift`);
    } else if (label === 'Booking Type') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Booking info') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}AppointmentInfo`);
    } else if (label === 'Evaluation Setting') {
      if (type === 'Update') {
        return this.roles.includes(`${type}EvaluationSetting`);
      } else {
        return this.roles.includes(`BoShowSuperAdmin`);
      }
    } else if (label === 'Page settings') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Service settings') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Roles groups') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'University') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}SchoolAndUniversity`);
    } else if (label === 'Section | College') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}SecAndCollage`);
    } else if (label === 'sub profession' || label === 'profession') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}secCollgProfession`);
    } else if (label === 'District') {
      return this.roles.includes(`${type}District`);
    } else if (label === 'Approved years') {
      if (type === 'Create') {
        type = 'Add';
      }
      return this.roles.includes(`${type}Year`);
    } else if (label === 'Organization') {
      return this.roles.includes(`${type}Organization`);
    } else if (label === 'Certificates status') {
      return this.roles.includes(`${type}CertVerTypeStatus`);
    } else if (label === 'Messages settings') {
      if (type === 'Update') {
        return this.roles.includes(`UpdateNotification`);
      } else {
        return this.roles.includes(`BoShowSuperAdmin`);
      }
    } else if (label === 'Website page') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'FO page') {
      return this.roles.includes(`${type}FoPageContent`);
    } else if (label === 'Approve settings') {
      return this.roles.includes(`${type}ApprovedStatusSetting`);
    } else if (label === 'Notification category') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Notifications') {
      if (type === 'Create' || type === 'Update') {
        return false;
      } else {
        return this.roles.includes(`BoShowSuperAdmin`);
      }
    } else if (label === 'Application settings') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'File status') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Membership type') {
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Chat service') {
      if (type === 'Delete') {
        return false;
      }
      return this.roles.includes(`BoShowSuperAdmin`);
    } else if (label === 'Documents Reasons') {
      return this.roles.includes(`${type}DocumentRequireReason`);
    } else {
      return true;
    }
  }

  uploadAppForm(row) {
    this.uploadApplicationForm.emit(row);
  }

  collectSelected(selected) {
    this.collectSelectedRow.emit(selected);
  }

  addPersonStatusHandeler(id) {
    this.addPersonStatus.emit({ personDataId: id });
  }

  toggleBlock(e, row) {
    this.blockUser.emit({ ...row, checked: e.checked });
  }

  takeWorkforceAction(row, type) {
    this.workforceAction.emit({ row, type });
  }

  downloadAllAsPdf(row, event) {
    event.stopPropagation();
    this.globalService.progressMessage.next({ loading: true });
    this.coreService
      .getRequest(`Document/CreatePdf?PersonId=${row?.personDataId}`, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) =>
          GetEventMessage(
            event,
            this.globalService,
            row?.personDataId,
            'Download in progress',
            'application/pdf',
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe((event: any) => {});
  }

  sendToNoc(row) {
    this.sendToNocAction.emit(row);
  }
}
