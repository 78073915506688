import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import * as moment from 'moment';
import {
  FilterRes,
  GetLanguage,
  getListTotal,
  openBrowserTab,
} from 'src/app/shared/functions/shared-functions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { secureStorage } from 'src/app/shared/functions/secure-storage';
import { DateAdapter } from '@angular/material/core';
import { TableColumn } from 'src/@config/interfaces/table-column.interface';
import { GlobalService } from 'src/app/services/global.service';
import { PageNumber, PageSize } from '../../../shared/variables/variables';

@UntilDestroy()
@Component({
  selector: 'cashier-list',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss'],
})
export class CashierComponent implements OnInit {
  @Input() isGroupedCashier = false;
  @Input() pageCode;
  @Input() listType;
  @Input() globalFilter;
  tableColumns: TableColumn<any>[] = [
    {
      label: '',
      property: 'selected',
      type: 'checkbox',
      visible: true,
      cssClasses: ['w-6'],
    },
    {
      label: 'File number',
      property: 'id',
      type: 'number',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Arabic name',
      property: 'arabicName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'English name',
      property: 'englishName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Civil Id',
      property: 'civilID',
      type: 'number',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Passport num',
      property: 'passportNum',
      type: 'number',
      visible: false,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Membership num',
      property: 'membershipNum',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Service',
      property: 'serviceName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'large-entry'],
    },
    {
      label: 'Total price',
      property: 'totalPrice',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Status',
      property: 'allStatus',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Residency end date',
      property: 'residencyEndDate',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Membership expiry date',
      property: 'membership_expiry',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary'],
    },
    {
      label: 'Requests',
      property: 'requests',
      type: 'button',
      visible: true,
      cssClasses: ['text-secondary', 'w-10'],
    },
  ];
  showTable = false;
  users: any = [];
  filter = [];
  backup = [];
  queryParams = '';
  total = 0;
  paginationEvent = {
    pageIndex: PageNumber,
    pageSize: PageSize,
    previousPageIndex: 0,
  };
  filters;

  constructor(
    private _CoreService: CoreService,
    private router: Router,
    private dateAdapter: DateAdapter<any>,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.dateAdapter.setLocale(GetLanguage());
  }

  ngOnInit(): void {
    // Important get data from resolver  removed because we're using this component in grouped cachier as tab not navigation
    // let tableData = this.activatedRoute.snapshot.data.accountingCashier?.body;
    // this.total = getListTotal(this.activatedRoute.snapshot.data.accountingCashier);
    // tableData = FilterRes(tableData);
    // this.handleTableObject(tableData);
    this.getData();
    this.subscribeToFilter();
    this.subscribeToGlobalSearch();
  }

  subscribeToFilter() {
    this.globalService.filterDataChangedSubject
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => {
        this.filters = value;
        this.queryParams = '';
        if (value) {
          if (value?.civil_ID) {
            this.queryParams += `&${
              value?.civil_ID ? 'PersoncivilID=' + value?.civil_ID : ''
            }`;
          }
          if (value?.passport) {
            this.queryParams += `&${
              value?.passport ? 'PersonpassportNum=' + value?.passport : ''
            }`;
          }
          if (value?.file_num) {
            this.queryParams += `&${
              value?.file_num ? 'PersonDataId=' + value?.file_num : ''
            }`;
          }
          if (value?.membership_num) {
            this.queryParams += `&${
              value?.membership_num
                ? 'MemberShinum=' + value?.membership_num
                : ''
            }`;
          }
          if (this.queryParams || value?.form_reset) {
            this.getData();
          }
        }
      });
  }

  resetPagination() {
    this.paginationEvent = {
      ...this.paginationEvent,
      pageIndex: PageNumber,
      pageSize: PageSize,
    };
  }

  getData(event?) {
    let pagination = '';
    let params = '';
    if (event?.pageIndex !== undefined) {
      this.paginationEvent = { ...event };
    } else if (event) {
      params = event;
    } else {
      this.resetPagination();
    }
    let pageCode;
    if (
      this.listType === 'classification' ||
      this.listType === 'engineers' ||
      this.listType === 'kuwaitis'
    ) {
      pageCode = this.pageCode;
    } else {
      pageCode = 'CaPosX2';
    }
    pagination = `PageSize=${this.paginationEvent.pageSize}&PageNumber=${this.paginationEvent.pageIndex}`;
    const URL =
      this.listType === 'kuwaitis'
        ? 'PersonData/GetAllPersonDatapageIntigrarion'
        : 'PersonData/GetAllPersonDatapage';
    this._CoreService
      .getRequest(
        `${URL}?pageCode=${pageCode}&${pagination}${
          params ? params : ''
        }${this.queryParams ? this.queryParams : ''}`,
        {
          observe: 'response',
          responseType: 'json',
        },
      )
      .subscribe(
        (res) => {
          this.handleTableObject(FilterRes(res?.body));
          this.total = getListTotal(res);
        },
        (err) => {},
      );
  }

  // collect(data) {
  //   data["cashierType"] = "cashier";
  //   const collectionData = data;
  //   secureStorage.setItem("collectionData", collectionData);
  //   this.router.navigate(["reception/service-collection"], {
  //     queryParams: {isGroupedCashier: this.isGroupedCashier},
  //   });
  // }

  handleTableObject(res) {
    const newArr = [];
    res?.map((item, index) => {
      item.allStatus = '';
      item.arabicName =
        item.firstNameAr +
        ' ' +
        (item.secondNameAr !== '-' ? item.secondNameAr : '') +
        ' ' +
        (item.thirdNameAr !== '-' ? item.thirdNameAr : '') +
        ' ' +
        item.familyNameAr;
      item.englishName =
        item.firstNameEN +
        ' ' +
        (item.secondNameEN !== '-' ? item.secondNameEN : '') +
        ' ' +
        (item.thirdNameEN !== '-' ? item.thirdNameEN : '') +
        ' ' +
        item.familyNameEN;
      item.date =
        item.personAppoinment && item.personAppoinment[0]?.appoinmentDate
          ? moment(item.personAppoinment[0]?.appoinmentDate)
              ?.locale('en')
              .format('yyyy-MM-DD')
          : ' - ';
      item.residencyEndDate = item.residencyEndDate
        ? moment(item.residencyEndDate)?.locale('en')?.format('yyyy-MM-DD')
        : '-';
      item.membership_expiry = item.personMembership?.expiryDate
        ? moment(item.personMembership?.expiryDate)
            ?.locale('en')
            ?.format('yyyy-MM-DD')
        : '-';
      item.serviceName =
        GetLanguage() === 'ar'
          ? item.serviceRequest[0].service.nameAr
          : item.serviceRequest[0].service.nameEn;
      item.membershipNum = item.personMembership?.membershipNum;
      item?.personStatus.map(
        (i) =>
          (item.allStatus +=
            (GetLanguage() === 'ar'
              ? i.statusType?.nameArabic
              : i.statusType?.nameEng) + ' - '),
      );
      item.allStatus = item?.allStatus?.slice(0, -2);
      FilterRes(item.serviceRequest)?.map((i) => {
        item.service = i.service;
        item.serviceRequest = i;
        item.serviceName =
          GetLanguage() === 'ar' ? i.service.nameAr : i.service.nameEn;
        if (
          i?.service?.actionCode === 'ToWhomConcernCertificateFee' &&
          i?.organization
        ) {
          item.serviceName = item.serviceName?.concat(
            ' - ' +
              (GetLanguage() === 'ar'
                ? i?.organization?.nameAr
                : i?.organization?.nameEn),
          );
        }
        item.serviceRequestId = i.id;
        item.serviceId = i.serviceId;
        item.totalPrice = i.totalPrice;
        newArr.push(JSON.parse(JSON.stringify(item)));
      });
    });
    this.backup = newArr;
    this.users = [...this.backup];
    this.showTable = true;
  }

  collectSelectedRow(rows) {
    rows?.map((row) => {
      this.cachierCollect(row);
    });
  }

  cachierCollect(data) {
    console.log('da', data);
    data['cashierType'] = 'cashier';
    const collectionData = data;

    collectionData.nameAr =
      collectionData.firstNameAr +
      ' ' +
      (collectionData.secondNameAr !== '-' ? collectionData.secondNameAr : '') +
      ' ' +
      (collectionData.thirdNameAr !== '-' ? collectionData.thirdNameAr : '') +
      ' ' +
      collectionData.familyNameAr;
    collectionData.nameEn =
      collectionData.firstNameEN +
      ' ' +
      (collectionData.secondNameEN !== '-' ? collectionData.secondNameEN : '') +
      ' ' +
      (collectionData.thirdNameEN !== '-' ? collectionData.thirdNameEN : '') +
      ' ' +
      collectionData.familyNameEN;
    collectionData.name =
      GetLanguage() === 'ar' ? collectionData.nameAr : collectionData.nameEn;

    // this.router.navigate(["reception/service-collection"], {
    //   queryParams: { isGroupedCashier: this.isGroupedCashier },
    // });
    secureStorage.setItem(
      `collectionData-${collectionData?.id}`,
      collectionData,
    );
    this.router.navigate(['service-collection'], {
      queryParams: {
        isGroupedCashier: this.isGroupedCashier,
        id: secureStorage.encryptItem(collectionData?.id),
        casheir_type: 'accounting',
        isKuwaiti: this.listType === 'kuwaitis',
      },
    });
    // window.open(`/service-collection?isGroupedCashier=${this.isGroupedCashier}&id=${secureStorage.encryptItem(collectionData?.id)}`, '_blank');
    // openBrowserTab(`/service-collection?isGroupedCashier=${this.isGroupedCashier}&id=${secureStorage.encryptItem(collectionData?.id)}`, collectionData?.id)
  }

  subscribeToGlobalSearch() {
    this.globalFilter?.pipe(untilDestroyed(this)).subscribe((value: any) => {
      //this.filters = value;
      this.queryParams = '';
      if (value?.civiLID) {
        this.queryParams += `&${'PersoncivilID=' + value?.civiLID}`;
      }
      if (value?.passportNo) {
        this.queryParams += `&${'PersonpassportNum=' + value?.passportNo}`;
      }
      if (value?.fileNo) {
        this.queryParams += `&${'PersonDataId=' + value?.fileNo}`;
      }
      if (this.queryParams || value?.form_reset) {
        this.getData();
      }
    });
  }
}
